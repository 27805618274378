.tasks-board-card {
  border-radius: 12px;
  min-height: 400px;
  max-height: 400px;
}

.tasks-board-card-header {
  font-size: large;
  font-weight: 600;
  padding: 10px 15px 0 15px;
}

.tasks-board-items {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  min-height: 70px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.tasks-board-item {
  background-color: #f1f2f4;
  border-radius: 8px;
  padding: 8px 12px 4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #091e4240, 0px 0px 1px #091e424f;
}

  .tasks-board-item .status-span {
    padding: 3px 7px 2px 7px;
    font-size: 12px;
    line-height: 12px;
    margin-right: 3px;
    border-radius: 8px !important;
    display: inline-block;
  }

  .tasks-board-item .text-span {
    font-weight: 600;
  }

.gu-mirror {
  pointer-events: none;
}