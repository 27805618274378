@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
.is-sticky{position:fixed;top:19px;width:96%;z-index:999;left:2%}.menuBar{color:#fff;width:28px;height:28px;cursor:pointer;display:none}@media(max-width: 991px){.menuBar{display:block}.align{align-items:flex-start !important}}
.header {
  position: relative;
  margin: -24px -40px;
  padding: 16px 16px;
}

.header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(201, 201, 201, 0.31);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
}

.tab-content {
  height: calc(100vh - 203px);
  overflow-y: scroll;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.time-container {
  border-radius: 5px;
  background-color: #efefef;
  padding: 6px;
}

.time-text {
  font-size: 11px;
  font-weight: 600;
}

.footer-menu {
  background-color: #efefef;
  margin: -24px -16px;
  padding: 5px 7px;
}

.nav-pills .nav-link {
  border-radius: 8px;
}

.nav-link.active .menu-icon {
  fill: #fff;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 55px;
  height: 15px;
  background: rgba(73, 255, 189, 0.1);
  border-radius: 150px/200px;
  position: relative;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
  padding: 0 6px;
  margin-top: -18px;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  width: 29px;
  height: 11px;
  transform: translateY(-50%);
  border-radius: 150px/200px;
  transition: 0.2s;
  background-color: #c7c7c7;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translate(-100%, -50%);
  background-color: #44aaa4;
}

.switch-label::after {
  content: "Complete";
  position: absolute;
  left: 50%;
  bottom: -22px;
  transform: translateX(-50%);
  font-size: 10px;
  color: #000000;
  font-weight: 500;
}

.switch-checkbox:checked + .switch-label::after {
  content: "Completed";
}

.job-details-list {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}

.job-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.job-details-list li:not(:last-child) {
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  padding-bottom: 6px;
}

.job-details-list li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #44aaa4;
  margin-right: 10px;
}

.job-details-list li span {
  font-size: 14px;
}

.job-details-list li span:first-child {
  width: 40%;
  color: #666666;
}

.job-details-list li span:last-child {
  font-weight: 500;
  color: #000;
}

.notes-text {
  font-size: 13px;
  line-height: 15.2px;
  color: #666666;
}

.contacts-wrapper {
  background-color: #efefef;
}

.contact-name {
  font-size: 12px;
  color: #000;
}

.contact-email {
  font-size: 10px;
  color: 666666;
}

.phone-number {
  font-size: 10px;
}

.contact-note {
  border-top: 1px solid #efefef;
}

.tag-btn {
  font-size: 12px;
  font-weight: 600;
}

.files-container {
  background-color: #efefef;
}

.tag {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 4px 6px;
}

.selected-tag {
  position: relative;
  background: #696767;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 4px 6px;
}

.tag-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  background-color: #df5757;
}

.tag-title {
  font-size: 10px;
}

.pt-65 {
  padding-top: 65px;
}

.tags-container {
  grid-gap: 10px;
  gap: 10px;
}

.grid-scrollable {
  height: calc(100vh - 410px);
  overflow-y: scroll;
}

.btn-outline-primary {
  border-color: transparent !important;
}
.btn-primary {
  border-color: transparent !important;
}

.arrow-btn {
  border: none;
  background: transparent;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  gap: 8px;
}

.grid img {
  max-width: 100%;
  height: auto;
}

.photo-badge {
  top: 0;
  left: 0;
  font-size: 9px;
  color: #c8c8c8;
  font-weight: 500;
  background-color: #000;
  padding: 1px 4px;
}

.files-btns {
  grid-gap: 10px;
  gap: 10px;
  margin: 25px 0 15px;
}

.files-btn-mobile {
  width: 150px;
  height: 70px;
  background-color: #efefef;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
}

.files-btn {
  width: 100px;
  height: 70px;
  background-color: #efefef;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
}

.files-btn:hover {
  background-color: #efefef;
}

.files-btn__title {
  font-size: 15px;
  color: #4f4443;
  font-weight: 700;
}

.modal-header .btn-close {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background: #d9d9d9 url(/static/media/close.0a73c451.svg) center no-repeat;
  padding: 0;
}

.modal-header-btn {
  font-weight: 500;
  font-size: 12px;
}

.font-weight-semibold {
  font-weight: 600;
}

.modal-content {
  border-radius: 10px;
}

.modal-header {
  padding: 10px;
  border-bottom: none;
}

.modal-body {
  padding: 0px 10px 30px;
}

.modal-tag {
  background-color: #d9d9d9;
  border-radius: 5px;
  padding: 1px 6px;
}

.remove-tag-btn {
  background-color: #d9d9d9;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}

.add-tag-btn {
  background-color: #0e4542;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}

.img-controls {
  background-color: #f5f5f5;
  padding: 0px 10px;
  grid-gap: 10px;
  gap: 10px;
}

.color-pallete-btns .btn-check:checked + .btn,
.color-pallete-btns .btn-check:checked + .btn:focus,
.color-pallete-btns .btn-check:active + .btn:focus,
.color-pallete-btns .btn:active:focus,
.btn.active:focus,
.color-pallete-btns .btn.dropdown-toggle.show:focus,
.btn-group .btn-check:checked + .btn-outline-primary,
.btn-group .btn-check:checked + .btn-outline-primary:focus,
.btn-group .btn-check:active + .btn-outline-primary:focus,
.btn-group .btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-group .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}

.job-container .btn-group .btn {
  border: none;
  margin: 0;
  border-radius: 5px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-outline-primary:hover:not(:disabled):not(.disabled) svg,
.btn-outline-primary.hover:not(:disabled):not(.disabled) svg,
.btn-group .btn-check:checked + .btn svg {
  fill: #fcfcfc;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-content {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.color-pallete {
  display: flex;
  justify-content: center;
}

.color-pallete-btns {
  background-color: #f5f5f5;
  border-radius: 50px;
}

.radio-color-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
}

.color-pallete-btns .btn {
  padding: 8px;
  background-color: transparent;
}

.modal-delete-btn {
  font-size: 12px;
  background-color: #efefef;
  border-radius: 7px;
  border: none;
  padding: 9px 17px;
}

.modal-delete-btn:hover span {
  color: #fff !important;
}

.img-loading {
  filter: blur(8px);
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
  background-color: #a1a1a1;
}
.img-loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.justify-self {
  justify-self: center;
}

@media (max-width: 768px) {
  .main {
    background-color: #fff;
    /* overflow-y: auto; */
  }

  .files-btns {
    margin: 15px 0 0 0;
  }

  .notes-or-memos {
    height: 100%;
    min-height: unset !important;
    max-height: 350px !important;
  }
}

.drop-area.active * {
  pointer-events: none;
}

.notes-or-memos {
  max-height: 250px;
  overflow: auto;
  font-size: 0.7rem;
  position: relative;
}

.notes-or-memos-container {
  background-color: #F5F5F5;
  padding: 8px;
  border-radius: 4px;
}

.notes-or-memos-container .note-or-memo-card {
  background-color: #F5F5F5;
  box-shadow: unset;
}

.note-or-memo-card:hover {
  background-color: #E9E9E9;
}

.notes-or-memos-container .note-or-memo-card .note-or-memo-card-body {
  padding: 5px !important;
  padding-bottom: 2px !important;
}

.note-or-memo-item {
  font-size: 13px;
  font-size: var(--bs-body-font-size, 13px);
}

.memo-created-by-user-name {
  font-weight: bold;
  margin-right: 4px;
}

.action-icon {
  cursor: pointer;
  visibility: hidden;
}

.note-or-memo-card:hover .action-icon {
  visibility: visible;
}

.note-or-memo-save-button {
  border-radius: 4px;
  float: right;
}

.note-or-memo-edit-group {
  position: relative;
}

.note-or-memo-edit-actions-container {
  position: absolute;
  right: 8px;
  bottom: 14px;
  border-radius: 4px;
}

.note-or-memo-edit-actions-button {
  border-radius: 4px;
}
.copy-btn {
    color: black;
    background: transparent;
    border: transparent;
    height: 10px;
    margin-bottom: 22px;
    margin-left: 3px !important;
}
.tab-files-container {
  position: relative;
}
.tab-files-container .spinner-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 40%);
}
.tab-files-container .file-upload-label {
  cursor: pointer;
}

.jobDetails_content .contentHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0rem;
    width: 96%;
    margin: 0 auto;
}

.jobDetails_content .contentHeading .part2 {
    display: flex;
    align-items: center;
}

.jobDetails_content .contentHeading .part1 h1 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    color: #494e54;
}

.jobDetails_content .contentHeading .part2 .part2Details {
    margin-right: 20px;
    text-align: right;
}

.jobDetails_content .contentHeading .part2 p {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #494e54;
}

.jobDetails_content .contentHeading .part2 p span {
    font-weight: 600;
}

.jobDetails_content .contentHeading .part2 p a {
    color: #494e54;
    text-decoration: underline;
}

.contentHeading .part2Btn {
    display: flex;
    align-items: center;
}

.contentHeading .part2Btn .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    color: black !important;
    outline: none !important;
    box-shadow: unset !important;
}

.contentHeading .part2Btn .dropdown-toggle:after {
    display: none !important;
}

.contentHeading .part2Btn .progressBtn {
    background: #3f80ea;
    border-radius: 55px;
    border: none;
    width: 132.83px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 30px;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}

.quoteContainer {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 235px;
}

.customLink {
    color: rgb(92, 92, 255);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

/* .......... */

.contentWrapper {
    width: 96%;
    margin: 0 auto;
    padding-bottom: 40px;
}

.cards_mainWrapper .cards_mainPart1 {
    width: 81% !important;
}

.cards_mainWrapper .cards_mainPart2 {
    width: 19% !important;
    position: relative;
}

.cards_mainWrapper .cards_mainPart2 .notes {
    position: absolute !important;
    top: 95% !important;
    margin: 0 20px;
}

.cards_mainWrapper .cards_mainPart2 .notes h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #898989;
    cursor: pointer;
}

.cards_innerWrapper {
    flex-wrap: wrap !important;
}

.cards_mainWrapper .cards_innerWrapper .cards_innerPart1 {
    width: 44% !important;
}

.cards_mainWrapper .cards_innerWrapper .cards_innerPart2 {
    width: 56% !important;
}


/* cards heights starts here */

.cards_mainWrapper .cards_innerWrapper .card {
    height: 70vh;
}


.cards_innerPart1 .tab-content {
    height: calc(70vh - 195px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.cards_innerPart2 .tab-content {
    height: calc(70vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.menu-button:after {
    display: none;
}

@media screen and (max-width: 768px) {
    .cards_mainWrapper .cards_innerWrapper .card {
        height: 100% !important;
    }
}


/* cards heights ends */

.contentWrapper .card .card-title {
    background: #3f80ea;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    padding: 8px 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}


/* customize react bootstrap tabs */

.buttonTabs .nav-tabs {
    border: none !important;
    display: flex;
    justify-content: start;
    padding: 5px 0px;
}

.buttonTabs .nav-tabs .nav-link {
    background: none;
    border: none !important;
    height: 29px;
    width: 100px;
    border-radius: 100px !important;
    margin-right: 5px;
    margin-bottom: 10px;
    line-height: 0px;
}

.buttonTabs2 .nav-tabs .nav-link {
    height: 32px;
    width: 120px;
}

.buttonTabs .nav-tabs .nav-link.active,
.buttonTabs .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    border-color: unset !important;
    background: #3f80ea !important;
}

.buttonTabs .nav-tabs .nav-link {
    color: #494e54;
    background: #e5e5e5 !important;
    border-radius: 10px;
    border-color: unset !important;
}


/* arrow tabs starts here */

.arrowTabs .nav-tabs {
    border: none !important;
    display: flex;
    justify-content: start;
}

.arrowTabs .nav-tabs .nav-link {
    background: none;
    border: none !important;
    height: 50px;
    width: 110px;
    margin-right: 1px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.arrowTabs .nav-tabs .nav-link p {
    text-align: start;
    margin: 0px;
    padding: 0px 0px 0px 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.arrowTabs .nav-tabs .nav-link p b {
    font-weight: 600;
}

.arrowTabs .nav-tabs .nav-link.active,
.arrowTabs .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    border-color: unset !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHMAAAAnCAYAAAA8XHcHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJhSURBVHgB7dxPbtNAFMfx94xgUxZlUVcIFuEG5QLFOUJPUPcG5QSZ3ICegHCCcANMLkBvQHaoBYlu2FDo8B52qqaN3RmPpadKv8+i+WM3m2/HtZ6TcOHOnCeeEFg5Zfo1rtyrC0rE+gNBzQ0SNNMflduVmHRCYGXP09acEmWrOwuXHzP5DwRWijfu/D0l4Duv6M5mcsg9JLAy++zyI+qBNz0pfyGf5KYgMCGL6d3C7bylSNmmJ5keH8jNKYEJ+Xd3vD/9Hn1Cym0bCvdz29OlrtA9AhOe2S0mO9PQ/blrYxP0i9wdEZiICZp1bazcswumv2O5uyQwwd670EMuh+xUuG8jT4/0kDsiMBGyQoNiKgS155nKxSRvnQUEx1QIaq8raFRMhaD22oJGx1SFO5dZIktQv01gQU5Mf8tg/uXaLKBXTIWg5u4E7R1TIai5taAZJahcLtfh/kTPEGEwMtR5MtfzGH2QtDJX5MVKOSlKunwDSZY63BkkpmquxZUEVqpBYu5Pfxyyv5oRGPGyMq/SVyZCWqtDVu75EmezD5teCHmtIfVB77NZhDQnIWm8CqkwAXqYmpD52gQoemXWs1maI6Qdpsuj2yFVVEwM2e3JipSQLz62bAuDkPbqkPmsbXvQykRIe0x+2hWy3uceCGmvDrnr7t+vQ+G+yiB3C+/OMxQaUrUeZpuQWJGGYkLW+29wIyTeAG0kNqTauDI9PdUrIAhpRGatJ7Eh69+7BZeybOnHKiVkST2sxURIWykh1fVhtnkLfElgpUoJqf7H1JD6mQYCK/qdBgeUiBHS3GDfNvIPpMkUoLml0ggAAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% 100% !important;
}

.arrowTabs .nav-tabs .nav-link.active b,
.arrowTabs .nav-tabs .nav-item.show .nav-link b {
    color: #ffffff;
}

.arrowTabs .nav-tabs .nav-link {
    color: #494e54;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAAnCAYAAADegGx+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI6SURBVHgB7dtNTsJAGMbxd2rCmi0BknIDr8ARPIH1Bt5APIHxBOIJPILuXOoNIOFr6xoS8H0MEJSWttMm72Ce30Ir7co/M20H6iaTySCKojshS8N2u30jNXD4wahBqCVqhB/dbnewXq/vhSwl8/n8QSqKdhvbqI9CZjabze1isag0U7q/L0yn06Fz7lrIjJ7+Bq1Wy2vGdGkvMqo936guawej2vOJGmXtWK1Wt/rrU8iMXtMMyp5T3amdo9Go2Wg0XnXzUshMmZHq8g5g1DBo1ESjPucdlxsUtlE/dDMWMlMkaqGgoHN5rHM6RmosZCYvauGgwKhh0AZ9XQh6S9tXKigwahC+dFWp3+l0ju5CSgcFRg1CalSvoMCoQTiKGoknPTGP9QTd182xkJWmrua96F1IvHvBOyggqr5DrnTzS8hKjHWCXVTvKfeQrvte6jsF029TyMp4uVz2awkKGjXRqE9CZvT//8YR+n/UM0K3V7tYFmRMOz8xe73euNJF0cGtC2Pa2cfEH7wPPW9H96FcKTpf9awUMWYQMtdyS51DGTMM2uAqLSYUDsqYYdCReZP10RlcSAH4xoKu274LY5pCTB2Zw1PH5I7Qg+8UxUJmisSEk0H5BbEw4LmjIjEh8yqXMcOAmHjuqOjxmSNUY+JJKMY0VDYmpI7Q2WyGT00SITM+MeEoKGPa840Jv6bc7QOniZCZKjFhHxQPxeCBUyEzVWPCz5SLmHjSSciMDqZnvTVJpCLHmPbqignf5m5QIJIEFXIAAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% 100% !important;
    border-color: unset !important;
}


/* arrow tabs ends here */

.disabledTab {
    opacity: 0.6;
}

/* media queries starts here */

@media screen and (max-width: 1200px) {
    .contentWrapper .card .card-title {
        padding: 8px 8px;
    }
}

@media screen and (max-width: 992px) {
    .cards_mainWrapper .cards_innerWrapper .cards_innerPart1 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_innerWrapper .cards_innerPart2 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_mainPart1 {
        width: 71% !important;
    }
    .cards_mainWrapper .cards_mainPart2 {
        width: 29% !important;
    }
    .jobDetails_content .contentHeading .part1 h1 {
        font-size: 30px !important;
    }
    .quoteContainer {
        height: 280px;
    }
}

@media screen and (max-width: 768px) {
    .cards_mainWrapper .cards_mainPart1 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_mainPart2 {
        width: 100% !important;
        margin: 40px 0;
    }
    .jobDetails_content .contentHeading {
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-start;
    }
    .jobDetails_content .contentHeading .part2 .part2Details {
        text-align: start !important;
    }
    .jobDetails_content .contentHeading .part1 h1 {
        font-size: 25px !important;
    }
    .contentWrapper .card .card-title {
        font-size: 12px !important;
    }
    .cards_mainWrapper .cards_mainPart2 .notes h3 {
        font-size: 14px !important;
    }
}

.invalidValue {
    border-color: #f43737;
}
.file-item {
    /* width: 150px;
    height: 200px; */
    /* padding: 10px; */
    margin: 5px;
}
.card {
    min-width: 150px !important;
    margin-bottom: unset !important;
}

.tooltip-inner {
    max-width: 330px !important;
    text-align: unset !important;
    background-color: white !important;
    color: #000000;
    max-height: 500px;
    overflow-y: auto;
}

.tooltip-arrow {
    display: none !important;
}

.form-select-sm {
    max-width: 50px !important;
}

.selected-comps {
    overflow: scroll;
}

.border-right {
    border-right: 1px #dee6ed solid !important;
}

.ag-theme-alpine {
    --ag-font-size: 12px !important;
    --ag-grid-size: 5px !important;
    --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3) !important;
    --ag-header-height: var(--ag-row-height) !important;
    /* //--ag-font-family: monospace !important; */
}

.form-select {
    background-image: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
    background-size: unset !important;
    padding: 0.15rem 0.25rem 0.25rem 0.85rem !important;
}

.btn-close {
    height: 17px;
    width: 17px;
}
body {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif !important;
}

.frontDesk {
    padding-bottom: 30px;
}

.front-container {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.frontDeskOne .form-select,
.frontDeskOne .form-control {
    background-color: #f5f5f5 !important;
    font-size: 13px;
    border: none !important;
    padding: 8px;
}

.frontDeskOne .form-select {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACTSURBVHgBdY49CoNAEIVnxiJtrpDCIl1yBLtUYQM5QDxJzBFyD0OWJaBlSjutBMHCI9iKP+MqKIvoa2bmvY/H4OV69xDgCZvCgql2rDxL/7Z9PADiaZXr2A3VN6JhD36fhwblkmGAl85GnyZzh7WrR2I0vUPle/MDZoMQYl91VqztMlD+2czIPKSUJVPrMDU3WKgHVyEru39bt6kAAAAASUVORK5CYII=) !important;
    background-size: 12px !important;
}

.frontDeskTwo {
    padding: 0 5px;
}

.frontDeskTwo .col {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #555555;
}

.front-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-switch .form-check-input {
    padding: 8px 0 !important;
    width: 30px;
    box-sizing: border-box !important;
}

.form-check-input[type="radio"] {
    padding: 7px !important;
}

label {
    margin-top: 2.5px;
}

.front-radios {
    display: flex;
    justify-content: center;
    align-items: center;
}

.front-radios button {
    background: #3f80ea !important;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
}

.front-radios button img {
    margin-right: 8px;
}

.front-radios button:hover {
    background: #3b45af !important;
    border-color: #3b45af !important;
    transition: all 0.8s ease;
}

.front-hr {
    background: #b2b2b2;
    height: 2px !important;
    border: none;
    width: 98% !important;
    margin: 0 auto;
}

.jobsContainer {
    height: 60vh;
    overflow-y: auto;
    padding-right: 30px;
}

.jobsContainer::-webkit-scrollbar {
    width: 8px;
    border-radius: 50px;
}

.jobsContainer::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 50px;
}

.jobsContainer ::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 50px;
}

.rl {
    padding: 3px 10px;
    display: flex;
    align-items: center;
}

.rl1 {
    background: #9d9d9d;
}

.rl2 {
    background: #eea12f;
}

.rl3 {
    background: #d9534f;
}

.pagination {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: right;
}

.switch {
    width: 100% !important;
}

.switch .switch-off,
.switch .switch-on {
    font-size: 14px;
}

.switch.btn-lg {
    min-height: 37px !important;
}
@media screen and (max-width: 768px) {
    .frontDeskTwo .front-switch {
        justify-content: space-between !important;
    }
    .frontDeskTwo .front-radios {
        justify-content: space-between !important;
    }
}

.mt-7p {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 0.65rem;
}

.filterContainer {
    display: flex;
    margin-left: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid rgb(154 173 183);
    border-radius: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.vl {
    border-left: 2px solid #5b6e77;
    margin: 0px 10px;
}

.custom-h4 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 0px;
}

.custom-checkbox input,
#show-my-jobs {
    display: none;
}
.custom-checkbox input + label:before,
#show-my-jobs + label:before {
    content: "";
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7ZnRbYMwEIbPpgNkBEboBqWTlA3IAqgWDNCskEmabNARPEIXAPpfRSRkOw0PFeco90mRsH0x99nmxUekKI+NuTbgnNtZaxtjTIVmRQJM0/SF9x/atj1ei0kKIPmyKIpPPJaUB34Yhlfk5cMBm4rOLHnmd0H5VIQDkUDXdTXllfyFEsdpH3Y+JQKboH3E9u1h/00bMh9jh8e3Sx++yZcwLtoBWD4v20jebZ08w+edFy7orsI4SysmIiHWLNxNgdxRAWlUQBoVkEYFpFEBaVRAGhWQRgWkUQFpVEAaFZBGBaRRAWnW3I3uSAi+ob4VkxI4LRu44v5YM9F/wwuHd78v+7jkFMZF9YFxHM+4h68WXTUmqvu+J2m4Xhb2RTsASw7ylB8+VeyLBPhOngtqlJeEn3OKMH/9a66XNWHVZkNO+J2R/EGiSqQo98APuQVh6Dr/kw4AAAAASUVORK5CYII=");
    background-repeat: round;
    margin-right: 12px;
}
.custom-checkbox input:checked + label:before,
#show-my-jobs:checked + label:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH/SURBVHgB7ZmBcYIwFIYf6ACOwAgdwU5SO4HtAFqqA9QNipNUN+gIjNAF1L6niDEEAuSF5M58d9xBiPj9PDRAAAKBxyaq25Gm6SSO43kURVPcnII9cvq6xWKxhR6MVY0on4xGox9cTcA+CS4bXPgCDCh/5U/XYb1ev8AlKPUtKxbLHVer1QwGlj+dTp9NHQr5DJcJ3Cp2RlWBubS9PRwOb3hZac+SDQR5kdKlUgH80T6J2yifeiYPYsVi3UFQPgcHNMi/LpfLsl0bwAVt5QnvAnSRJ7wK0FWe8CZAH3nCiwB95QnnAUzkCacBTOUJZwE45AknAbjkiTEYUneXqOmfye195AmOCqRwu0vMCkEl3PKEcQXgIi9CIUCuRF95XYWNK4AC74rmu0oYnvkUFM8BV4wrQAL4EES34d/SrnMlruvy5zpcNomwLleb51+IREhIsSsD5mtehu1vtCHEHZzyBOs4oAvBLU+wD2R1IWzIE1ZGYiFEDpe3DlbkCY5xQEkhnIFlvHwm7kII4JoQwDUhgGvavBudgCNookXXRxVgJ27gZMdXmwNxQycOv/tDbMMR/VfuVxmJj8fjHufGpkLTDA80E+7tnYHPHJUHmkoFMCV1ysE/ctULg0oAmszASY1n8CtEXjhViJo+VcyXzeVZmwHZ4bJH+Y2rWaJAwHf+AbnqN55gpgNqAAAAAElFTkSuQmCC");
}

.custom-checkbox input,
#include-inspections {
    display: none;
}
.custom-checkbox input + label:before,
#include-inspections + label:before {
    content: "";
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7ZnRbYMwEIbPpgNkBEboBqWTlA3IAqgWDNCskEmabNARPEIXAPpfRSRkOw0PFeco90mRsH0x99nmxUekKI+NuTbgnNtZaxtjTIVmRQJM0/SF9x/atj1ei0kKIPmyKIpPPJaUB34Yhlfk5cMBm4rOLHnmd0H5VIQDkUDXdTXllfyFEsdpH3Y+JQKboH3E9u1h/00bMh9jh8e3Sx++yZcwLtoBWD4v20jebZ08w+edFy7orsI4SysmIiHWLNxNgdxRAWlUQBoVkEYFpFEBaVRAGhWQRgWkUQFpVEAaFZBGBaRRAWnW3I3uSAi+ob4VkxI4LRu44v5YM9F/wwuHd78v+7jkFMZF9YFxHM+4h68WXTUmqvu+J2m4Xhb2RTsASw7ylB8+VeyLBPhOngtqlJeEn3OKMH/9a66XNWHVZkNO+J2R/EGiSqQo98APuQVh6Dr/kw4AAAAASUVORK5CYII=");
    background-repeat: round;
    margin-right: 10px;
}
.custom-checkbox input:checked + label:before,
#include-inspections:checked + label:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH/SURBVHgB7ZmBcYIwFIYf6ACOwAgdwU5SO4HtAFqqA9QNipNUN+gIjNAF1L6niDEEAuSF5M58d9xBiPj9PDRAAAKBxyaq25Gm6SSO43kURVPcnII9cvq6xWKxhR6MVY0on4xGox9cTcA+CS4bXPgCDCh/5U/XYb1ev8AlKPUtKxbLHVer1QwGlj+dTp9NHQr5DJcJ3Cp2RlWBubS9PRwOb3hZac+SDQR5kdKlUgH80T6J2yifeiYPYsVi3UFQPgcHNMi/LpfLsl0bwAVt5QnvAnSRJ7wK0FWe8CZAH3nCiwB95QnnAUzkCacBTOUJZwE45AknAbjkiTEYUneXqOmfye195AmOCqRwu0vMCkEl3PKEcQXgIi9CIUCuRF95XYWNK4AC74rmu0oYnvkUFM8BV4wrQAL4EES34d/SrnMlruvy5zpcNomwLleb51+IREhIsSsD5mtehu1vtCHEHZzyBOs4oAvBLU+wD2R1IWzIE1ZGYiFEDpe3DlbkCY5xQEkhnIFlvHwm7kII4JoQwDUhgGvavBudgCNookXXRxVgJ27gZMdXmwNxQycOv/tDbMMR/VfuVxmJj8fjHufGpkLTDA80E+7tnYHPHJUHmkoFMCV1ysE/ctULg0oAmszASY1n8CtEXjhViJo+VcyXzeVZmwHZ4bJH+Y2rWaJAwHf+AbnqN55gpgNqAAAAAElFTkSuQmCC");
}

.step-badge-wrapper {
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
}

.step-badge {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    position: absolute;
    transition-duration: 0.5s;
}
.step-badge-relative-wrapper {
    position: relative;
    top: -50%;
    left: 0;
}
.step-badge .assigned-user {
    opacity: 0;
    transition-duration: 0.5s;
}

.step-badge.expandable:hover {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    height: 46px;
    z-index: 99;
    margin-left: -12px;
    margin-top: -12px;
}
.step-badge.expandable:hover .assigned-user {
    opacity: 1;
}

.assigned-user {
    margin-top: 10px;
}

.tasks-board-card {
  border-radius: 12px;
  min-height: 400px;
  max-height: 400px;
}

.tasks-board-card-header {
  font-size: large;
  font-weight: 600;
  padding: 10px 15px 0 15px;
}

.tasks-board-items {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  min-height: 70px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.tasks-board-item {
  background-color: #f1f2f4;
  border-radius: 8px;
  padding: 8px 12px 4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #091e4240, 0px 0px 1px #091e424f;
}

  .tasks-board-item .status-span {
    padding: 3px 7px 2px 7px;
    font-size: 12px;
    line-height: 12px;
    margin-right: 3px;
    border-radius: 8px !important;
    display: inline-block;
  }

  .tasks-board-item .text-span {
    font-weight: 600;
  }

.gu-mirror {
  pointer-events: none;
}
.eventTitle {
    margin-left: 2px;
    margin-bottom: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    line-height: 2;
    color: #6c757d;
    font-size: 14px;
}

.event:hover {
    background: #cac9c9;
}

.text-overflow {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icons-container {
    align-self: center;
    height: 19px;
}

.events-container {
    max-width: 47px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #44AAA4;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
}

.events-container img {
    max-width: 27px;
}

.internal tbody[role=presentation] {
    background: url(/static/media/internal-calendar-bg.cd71efb0.svg);
    background-size: cover;
    background-attachment: fixed;
}

.internal .fc-theme-bootstrap {
    border-color: #aa644a40;
}

.form-check-label {
    line-height: 1;
}

.users-dropdown {
    height: 33px;
}

.regular-event {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
    border-radius: 3px;
    position: relative;
}

.red-border-left {
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #FF0000;
}

.black {
    color: black;
    font-weight: 700;
}

.white {
    color: white;
}

.rush-container {
    background-color: #FF0000;
    padding: 0px 3px 1px;
    border-radius: 3px;
    margin-left: 3px;
}

.inspection-required-container {
    background-color: #ffffffbf;
    padding: 0px 3px 1px;
    border-radius: 3px;
}

.portfolio-title {
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2px;
}

.portfolio-ticket-title {
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    white-space: pre-wrap;
    color: white !important;
    margin-bottom: unset;
    padding-right: 4px;
    font-size: 12px;
}

.fc-timeGridWeek-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body td {
    height: calc(100vh - 252px);
}

.fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body td {
    height: calc(100vh - 252px);
}

.fc-timeGridWeek-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    display: none;
}

.fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    display: none;
}

.hidden {
    display: none;
}

.past-days {
    background-color: #FF0000;
    padding: 1px 3px 1px 3px;
    border-radius: 3px;
    font-size: 10px;
    margin: 0px;
}

.calendar-due-date-switch {
    min-width: 200px !important;
}

.form-check-label {
    line-height: unset !important;
}

.content {
    padding: 0.5rem 2.5rem 0.5rem !important;
}

.calendar-title {
    display: flex;
    align-self: center;
    margin-bottom: 0px;
}

.actions-container .is-disabled {
    pointer-events: none;
    opacity: 0.7;
}

@media (max-width: 768px) {
    .fc .fc-popover {
        top: 0 !important;
    }

    /* .fc .fc-daygrid-day-frame {
        height: 120px;
    } */
    /* .fc .fc-daygrid-day-bottom {
        font-size: 24px;
        margin: 0 10px;
    } */
    .fc-dayGridMonth-button,
    .fc-listWeek-button {
        background-color: #EDEDED;
        border-color: #EDEDED;
        color: #000;
        border-radius: 3px !important;
        padding: 1px 8px;
        font-size: 17px;
    }

    .fc-dayGridMonth-button {
        margin-left: 6px !important;
    }

    .fc-direction-ltr .fc-list-day-side-text {
        float: none !important;
    }

    .fc .fc-toolbar-title {
        font-size: 16px;
    }
}
.fc-event.fc-h-event {
  border: none;
}
