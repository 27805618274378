.is-sticky {
    position: fixed;
    top: 19px;
    width: 96%;
    z-index: 999;
    left: 2%;
}

.menuBar{
    color: white;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: none;
}
@media (max-width: 991px) {
    .menuBar{
        display: block;
    }
    .align{
        align-items: flex-start !important;
    }
  }