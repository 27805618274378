.tab-files-container {
  position: relative;
}
.tab-files-container .spinner-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 40%);
}
.tab-files-container .file-upload-label {
  cursor: pointer;
}
