.card {
    min-width: 150px !important;
    margin-bottom: unset !important;
}

.tooltip-inner {
    max-width: 330px !important;
    text-align: unset !important;
    background-color: white !important;
    color: #000000;
    max-height: 500px;
    overflow-y: auto;
}

.tooltip-arrow {
    display: none !important;
}

.form-select-sm {
    max-width: 50px !important;
}

.selected-comps {
    overflow: scroll;
}

.border-right {
    border-right: 1px #dee6ed solid !important;
}

.ag-theme-alpine {
    --ag-font-size: 12px !important;
    --ag-grid-size: 5px !important;
    --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3) !important;
    --ag-header-height: var(--ag-row-height) !important;
    /* //--ag-font-family: monospace !important; */
}

.form-select {
    background-image: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
    background-size: unset !important;
    padding: 0.15rem 0.25rem 0.25rem 0.85rem !important;
}

.btn-close {
    height: 17px;
    width: 17px;
}