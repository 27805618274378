.header {
  position: relative;
  margin: -24px -40px;
  padding: 16px 16px;
}

.header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(201, 201, 201, 0.31);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
}

.tab-content {
  height: calc(100vh - 203px);
  overflow-y: scroll;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.time-container {
  border-radius: 5px;
  background-color: #efefef;
  padding: 6px;
}

.time-text {
  font-size: 11px;
  font-weight: 600;
}

.footer-menu {
  background-color: #efefef;
  margin: -24px -16px;
  padding: 5px 7px;
}

.nav-pills .nav-link {
  border-radius: 8px;
}

.nav-link.active .menu-icon {
  fill: #fff;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 55px;
  height: 15px;
  background: rgba(73, 255, 189, 0.1);
  border-radius: 150px/200px;
  position: relative;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
  padding: 0 6px;
  margin-top: -18px;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  width: 29px;
  height: 11px;
  transform: translateY(-50%);
  border-radius: 150px/200px;
  transition: 0.2s;
  background-color: #c7c7c7;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translate(-100%, -50%);
  background-color: #44aaa4;
}

.switch-label::after {
  content: "Complete";
  position: absolute;
  left: 50%;
  bottom: -22px;
  transform: translateX(-50%);
  font-size: 10px;
  color: #000000;
  font-weight: 500;
}

.switch-checkbox:checked + .switch-label::after {
  content: "Completed";
}

.job-details-list {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}

.job-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.job-details-list li:not(:last-child) {
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  padding-bottom: 6px;
}

.job-details-list li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #44aaa4;
  margin-right: 10px;
}

.job-details-list li span {
  font-size: 14px;
}

.job-details-list li span:first-child {
  width: 40%;
  color: #666666;
}

.job-details-list li span:last-child {
  font-weight: 500;
  color: #000;
}

.notes-text {
  font-size: 13px;
  line-height: 15.2px;
  color: #666666;
}

.contacts-wrapper {
  background-color: #efefef;
}

.contact-name {
  font-size: 12px;
  color: #000;
}

.contact-email {
  font-size: 10px;
  color: 666666;
}

.phone-number {
  font-size: 10px;
}

.contact-note {
  border-top: 1px solid #efefef;
}

.tag-btn {
  font-size: 12px;
  font-weight: 600;
}

.files-container {
  background-color: #efefef;
}

.tag {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 4px 6px;
}

.selected-tag {
  position: relative;
  background: #696767;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 4px 6px;
}

.tag-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  background-color: #df5757;
}

.tag-title {
  font-size: 10px;
}

.pt-65 {
  padding-top: 65px;
}

.tags-container {
  gap: 10px;
}

.grid-scrollable {
  height: calc(100vh - 410px);
  overflow-y: scroll;
}

.btn-outline-primary {
  border-color: transparent !important;
}
.btn-primary {
  border-color: transparent !important;
}

.arrow-btn {
  border: none;
  background: transparent;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.grid img {
  max-width: 100%;
  height: auto;
}

.photo-badge {
  top: 0;
  left: 0;
  font-size: 9px;
  color: #c8c8c8;
  font-weight: 500;
  background-color: #000;
  padding: 1px 4px;
}

.files-btns {
  gap: 10px;
  margin: 25px 0 15px;
}

.files-btn-mobile {
  width: 150px;
  height: 70px;
  background-color: #efefef;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
}

.files-btn {
  width: 100px;
  height: 70px;
  background-color: #efefef;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
}

.files-btn:hover {
  background-color: #efefef;
}

.files-btn__title {
  font-size: 15px;
  color: #4f4443;
  font-weight: 700;
}

.modal-header .btn-close {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background: #d9d9d9 url("../../assets/icons/close.svg") center no-repeat;
  padding: 0;
}

.modal-header-btn {
  font-weight: 500;
  font-size: 12px;
}

.font-weight-semibold {
  font-weight: 600;
}

.modal-content {
  border-radius: 10px;
}

.modal-header {
  padding: 10px;
  border-bottom: none;
}

.modal-body {
  padding: 0px 10px 30px;
}

.modal-tag {
  background-color: #d9d9d9;
  border-radius: 5px;
  padding: 1px 6px;
}

.remove-tag-btn {
  background-color: #d9d9d9;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}

.add-tag-btn {
  background-color: #0e4542;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}

.img-controls {
  background-color: #f5f5f5;
  padding: 0px 10px;
  gap: 10px;
}

.color-pallete-btns .btn-check:checked + .btn,
.color-pallete-btns .btn-check:checked + .btn:focus,
.color-pallete-btns .btn-check:active + .btn:focus,
.color-pallete-btns .btn:active:focus,
.btn.active:focus,
.color-pallete-btns .btn.dropdown-toggle.show:focus,
.btn-group .btn-check:checked + .btn-outline-primary,
.btn-group .btn-check:checked + .btn-outline-primary:focus,
.btn-group .btn-check:active + .btn-outline-primary:focus,
.btn-group .btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-group .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}

.job-container .btn-group .btn {
  border: none;
  margin: 0;
  border-radius: 5px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-outline-primary:hover:not(:disabled):not(.disabled) svg,
.btn-outline-primary.hover:not(:disabled):not(.disabled) svg,
.btn-group .btn-check:checked + .btn svg {
  fill: #fcfcfc;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-content {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.color-pallete {
  display: flex;
  justify-content: center;
}

.color-pallete-btns {
  background-color: #f5f5f5;
  border-radius: 50px;
}

.radio-color-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
}

.color-pallete-btns .btn {
  padding: 8px;
  background-color: transparent;
}

.modal-delete-btn {
  font-size: 12px;
  background-color: #efefef;
  border-radius: 7px;
  border: none;
  padding: 9px 17px;
}

.modal-delete-btn:hover span {
  color: #fff !important;
}

.img-loading {
  filter: blur(8px);
  clip-path: inset(0);
  background-color: #a1a1a1;
}
.img-loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.justify-self {
  justify-self: center;
}

@media (max-width: 768px) {
  .main {
    background-color: #fff;
    /* overflow-y: auto; */
  }

  .files-btns {
    margin: 15px 0 0 0;
  }

  .notes-or-memos {
    height: 100%;
    min-height: unset !important;
    max-height: 350px !important;
  }
}
