@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif !important;
}

.frontDesk {
    padding-bottom: 30px;
}

.front-container {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.frontDeskOne .form-select,
.frontDeskOne .form-control {
    background-color: #f5f5f5 !important;
    font-size: 13px;
    border: none !important;
    padding: 8px;
}

.frontDeskOne .form-select {
    background-image: url(../../assets/icons/arrowDown.png) !important;
    background-size: 12px !important;
}

.frontDeskTwo {
    padding: 0 5px;
}

.frontDeskTwo .col {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #555555;
}

.front-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-switch .form-check-input {
    padding: 8px 0 !important;
    width: 30px;
    box-sizing: border-box !important;
}

.form-check-input[type="radio"] {
    padding: 7px !important;
}

label {
    margin-top: 2.5px;
}

.front-radios {
    display: flex;
    justify-content: center;
    align-items: center;
}

.front-radios button {
    background: #3f80ea !important;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
}

.front-radios button img {
    margin-right: 8px;
}

.front-radios button:hover {
    background: #3b45af !important;
    border-color: #3b45af !important;
    transition: all 0.8s ease;
}

.front-hr {
    background: #b2b2b2;
    height: 2px !important;
    border: none;
    width: 98% !important;
    margin: 0 auto;
}

.jobsContainer {
    height: 60vh;
    overflow-y: auto;
    padding-right: 30px;
}

.jobsContainer::-webkit-scrollbar {
    width: 8px;
    border-radius: 50px;
}

.jobsContainer::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 50px;
}

.jobsContainer ::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 50px;
}

.rl {
    padding: 3px 10px;
    display: flex;
    align-items: center;
}

.rl1 {
    background: #9d9d9d;
}

.rl2 {
    background: #eea12f;
}

.rl3 {
    background: #d9534f;
}

.pagination {
    width: fit-content;
    float: right;
}

.switch {
    width: 100% !important;
}

.switch .switch-off,
.switch .switch-on {
    font-size: 14px;
}

.switch.btn-lg {
    min-height: 37px !important;
}
@media screen and (max-width: 768px) {
    .frontDeskTwo .front-switch {
        justify-content: space-between !important;
    }
    .frontDeskTwo .front-radios {
        justify-content: space-between !important;
    }
}

.mt-7p {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 0.65rem;
}

.filterContainer {
    display: flex;
    margin-left: 10px;
    width: fit-content;
    border: 2px solid rgb(154 173 183);
    border-radius: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.vl {
    border-left: 2px solid #5b6e77;
    margin: 0px 10px;
}

.custom-h4 {
    width: fit-content;
    padding-right: 0px;
}

.custom-checkbox input,
#show-my-jobs {
    display: none;
}
.custom-checkbox input + label:before,
#show-my-jobs + label:before {
    content: "";
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7ZnRbYMwEIbPpgNkBEboBqWTlA3IAqgWDNCskEmabNARPEIXAPpfRSRkOw0PFeco90mRsH0x99nmxUekKI+NuTbgnNtZaxtjTIVmRQJM0/SF9x/atj1ei0kKIPmyKIpPPJaUB34Yhlfk5cMBm4rOLHnmd0H5VIQDkUDXdTXllfyFEsdpH3Y+JQKboH3E9u1h/00bMh9jh8e3Sx++yZcwLtoBWD4v20jebZ08w+edFy7orsI4SysmIiHWLNxNgdxRAWlUQBoVkEYFpFEBaVRAGhWQRgWkUQFpVEAaFZBGBaRRAWnW3I3uSAi+ob4VkxI4LRu44v5YM9F/wwuHd78v+7jkFMZF9YFxHM+4h68WXTUmqvu+J2m4Xhb2RTsASw7ylB8+VeyLBPhOngtqlJeEn3OKMH/9a66XNWHVZkNO+J2R/EGiSqQo98APuQVh6Dr/kw4AAAAASUVORK5CYII=");
    background-repeat: round;
    margin-right: 12px;
}
.custom-checkbox input:checked + label:before,
#show-my-jobs:checked + label:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH/SURBVHgB7ZmBcYIwFIYf6ACOwAgdwU5SO4HtAFqqA9QNipNUN+gIjNAF1L6niDEEAuSF5M58d9xBiPj9PDRAAAKBxyaq25Gm6SSO43kURVPcnII9cvq6xWKxhR6MVY0on4xGox9cTcA+CS4bXPgCDCh/5U/XYb1ev8AlKPUtKxbLHVer1QwGlj+dTp9NHQr5DJcJ3Cp2RlWBubS9PRwOb3hZac+SDQR5kdKlUgH80T6J2yifeiYPYsVi3UFQPgcHNMi/LpfLsl0bwAVt5QnvAnSRJ7wK0FWe8CZAH3nCiwB95QnnAUzkCacBTOUJZwE45AknAbjkiTEYUneXqOmfye195AmOCqRwu0vMCkEl3PKEcQXgIi9CIUCuRF95XYWNK4AC74rmu0oYnvkUFM8BV4wrQAL4EES34d/SrnMlruvy5zpcNomwLleb51+IREhIsSsD5mtehu1vtCHEHZzyBOs4oAvBLU+wD2R1IWzIE1ZGYiFEDpe3DlbkCY5xQEkhnIFlvHwm7kII4JoQwDUhgGvavBudgCNookXXRxVgJ27gZMdXmwNxQycOv/tDbMMR/VfuVxmJj8fjHufGpkLTDA80E+7tnYHPHJUHmkoFMCV1ysE/ctULg0oAmszASY1n8CtEXjhViJo+VcyXzeVZmwHZ4bJH+Y2rWaJAwHf+AbnqN55gpgNqAAAAAElFTkSuQmCC");
}

.custom-checkbox input,
#include-inspections {
    display: none;
}
.custom-checkbox input + label:before,
#include-inspections + label:before {
    content: "";
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7ZnRbYMwEIbPpgNkBEboBqWTlA3IAqgWDNCskEmabNARPEIXAPpfRSRkOw0PFeco90mRsH0x99nmxUekKI+NuTbgnNtZaxtjTIVmRQJM0/SF9x/atj1ei0kKIPmyKIpPPJaUB34Yhlfk5cMBm4rOLHnmd0H5VIQDkUDXdTXllfyFEsdpH3Y+JQKboH3E9u1h/00bMh9jh8e3Sx++yZcwLtoBWD4v20jebZ08w+edFy7orsI4SysmIiHWLNxNgdxRAWlUQBoVkEYFpFEBaVRAGhWQRgWkUQFpVEAaFZBGBaRRAWnW3I3uSAi+ob4VkxI4LRu44v5YM9F/wwuHd78v+7jkFMZF9YFxHM+4h68WXTUmqvu+J2m4Xhb2RTsASw7ylB8+VeyLBPhOngtqlJeEn3OKMH/9a66XNWHVZkNO+J2R/EGiSqQo98APuQVh6Dr/kw4AAAAASUVORK5CYII=");
    background-repeat: round;
    margin-right: 10px;
}
.custom-checkbox input:checked + label:before,
#include-inspections:checked + label:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH/SURBVHgB7ZmBcYIwFIYf6ACOwAgdwU5SO4HtAFqqA9QNipNUN+gIjNAF1L6niDEEAuSF5M58d9xBiPj9PDRAAAKBxyaq25Gm6SSO43kURVPcnII9cvq6xWKxhR6MVY0on4xGox9cTcA+CS4bXPgCDCh/5U/XYb1ev8AlKPUtKxbLHVer1QwGlj+dTp9NHQr5DJcJ3Cp2RlWBubS9PRwOb3hZac+SDQR5kdKlUgH80T6J2yifeiYPYsVi3UFQPgcHNMi/LpfLsl0bwAVt5QnvAnSRJ7wK0FWe8CZAH3nCiwB95QnnAUzkCacBTOUJZwE45AknAbjkiTEYUneXqOmfye195AmOCqRwu0vMCkEl3PKEcQXgIi9CIUCuRF95XYWNK4AC74rmu0oYnvkUFM8BV4wrQAL4EES34d/SrnMlruvy5zpcNomwLleb51+IREhIsSsD5mtehu1vtCHEHZzyBOs4oAvBLU+wD2R1IWzIE1ZGYiFEDpe3DlbkCY5xQEkhnIFlvHwm7kII4JoQwDUhgGvavBudgCNookXXRxVgJ27gZMdXmwNxQycOv/tDbMMR/VfuVxmJj8fjHufGpkLTDA80E+7tnYHPHJUHmkoFMCV1ysE/ctULg0oAmszASY1n8CtEXjhViJo+VcyXzeVZmwHZ4bJH+Y2rWaJAwHf+AbnqN55gpgNqAAAAAElFTkSuQmCC");
}

.step-badge-wrapper {
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
}

.step-badge {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    position: absolute;
    transition-duration: 0.5s;
}
.step-badge-relative-wrapper {
    position: relative;
    top: -50%;
    left: 0;
}
.step-badge .assigned-user {
    opacity: 0;
    transition-duration: 0.5s;
}

.step-badge.expandable:hover {
    width: min-content;
    height: 46px;
    z-index: 99;
    margin-left: -12px;
    margin-top: -12px;
}
.step-badge.expandable:hover .assigned-user {
    opacity: 1;
}

.assigned-user {
    margin-top: 10px;
}
