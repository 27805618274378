.eventTitle {
    margin-left: 2px;
    margin-bottom: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 3;
    position: sticky;
    line-height: 2;
    color: #6c757d;
    font-size: 14px;
}

.event:hover {
    background: #cac9c9;
}

.text-overflow {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icons-container {
    align-self: center;
    height: 19px;
}

.events-container {
    max-width: 47px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #44AAA4;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
}

.events-container img {
    max-width: 27px;
}

.internal tbody[role=presentation] {
    background: url(../../assets/img/internal-calendar-bg.svg);
    background-size: cover;
    background-attachment: fixed;
}

.internal .fc-theme-bootstrap {
    border-color: #aa644a40;
}

.form-check-label {
    line-height: 1;
}

.users-dropdown {
    height: 33px;
}

.regular-event {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
    border-radius: 3px;
    position: relative;
}

.red-border-left {
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #FF0000;
}

.black {
    color: black;
    font-weight: 700;
}

.white {
    color: white;
}

.rush-container {
    background-color: #FF0000;
    padding: 0px 3px 1px;
    border-radius: 3px;
    margin-left: 3px;
}

.inspection-required-container {
    background-color: #ffffffbf;
    padding: 0px 3px 1px;
    border-radius: 3px;
}

.portfolio-title {
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2px;
}

.portfolio-ticket-title {
    z-index: 3;
    position: sticky;
    white-space: pre-wrap;
    color: white !important;
    margin-bottom: unset;
    padding-right: 4px;
    font-size: 12px;
}

.fc-timeGridWeek-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body td {
    height: calc(100vh - 252px);
}

.fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body td {
    height: calc(100vh - 252px);
}

.fc-timeGridWeek-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    display: none;
}

.fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    display: none;
}

.hidden {
    display: none;
}

.past-days {
    background-color: #FF0000;
    padding: 1px 3px 1px 3px;
    border-radius: 3px;
    font-size: 10px;
    margin: 0px;
}

.calendar-due-date-switch {
    min-width: 200px !important;
}

.form-check-label {
    line-height: unset !important;
}

.content {
    padding: 0.5rem 2.5rem 0.5rem !important;
}

.calendar-title {
    display: flex;
    align-self: center;
    margin-bottom: 0px;
}

.actions-container .is-disabled {
    pointer-events: none;
    opacity: 0.7;
}

@media (max-width: 768px) {
    .fc .fc-popover {
        top: 0 !important;
    }

    /* .fc .fc-daygrid-day-frame {
        height: 120px;
    } */
    /* .fc .fc-daygrid-day-bottom {
        font-size: 24px;
        margin: 0 10px;
    } */
    .fc-dayGridMonth-button,
    .fc-listWeek-button {
        background-color: #EDEDED;
        border-color: #EDEDED;
        color: #000;
        border-radius: 3px !important;
        padding: 1px 8px;
        font-size: 17px;
    }

    .fc-dayGridMonth-button {
        margin-left: 6px !important;
    }

    .fc-direction-ltr .fc-list-day-side-text {
        float: none !important;
    }

    .fc .fc-toolbar-title {
        font-size: 16px;
    }
}