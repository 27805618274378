.notes-or-memos {
  max-height: 250px;
  overflow: auto;
  font-size: 0.7rem;
  position: relative;
}

.notes-or-memos-container {
  background-color: #F5F5F5;
  padding: 8px;
  border-radius: 4px;
}

.notes-or-memos-container .note-or-memo-card {
  background-color: #F5F5F5;
  box-shadow: unset;
}

.note-or-memo-card:hover {
  background-color: #E9E9E9;
}

.notes-or-memos-container .note-or-memo-card .note-or-memo-card-body {
  padding: 5px !important;
  padding-bottom: 2px !important;
}

.note-or-memo-item {
  font-size: var(--bs-body-font-size, 13px);
}

.memo-created-by-user-name {
  font-weight: bold;
  margin-right: 4px;
}

.action-icon {
  cursor: pointer;
  visibility: hidden;
}

.note-or-memo-card:hover .action-icon {
  visibility: visible;
}

.note-or-memo-save-button {
  border-radius: 4px;
  float: right;
}

.note-or-memo-edit-group {
  position: relative;
}

.note-or-memo-edit-actions-container {
  position: absolute;
  right: 8px;
  bottom: 14px;
  border-radius: 4px;
}

.note-or-memo-edit-actions-button {
  border-radius: 4px;
}