@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.jobDetails_content .contentHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0rem;
    width: 96%;
    margin: 0 auto;
}

.jobDetails_content .contentHeading .part2 {
    display: flex;
    align-items: center;
}

.jobDetails_content .contentHeading .part1 h1 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    color: #494e54;
}

.jobDetails_content .contentHeading .part2 .part2Details {
    margin-right: 20px;
    text-align: right;
}

.jobDetails_content .contentHeading .part2 p {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #494e54;
}

.jobDetails_content .contentHeading .part2 p span {
    font-weight: 600;
}

.jobDetails_content .contentHeading .part2 p a {
    color: #494e54;
    text-decoration: underline;
}

.contentHeading .part2Btn {
    display: flex;
    align-items: center;
}

.contentHeading .part2Btn .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    color: black !important;
    outline: none !important;
    box-shadow: unset !important;
}

.contentHeading .part2Btn .dropdown-toggle:after {
    display: none !important;
}

.contentHeading .part2Btn .progressBtn {
    background: #3f80ea;
    border-radius: 55px;
    border: none;
    width: 132.83px;
    height: fit-content;
    min-height: 30px;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
}

.quoteContainer {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 235px;
}

.customLink {
    color: rgb(92, 92, 255);
    width: fit-content;
    cursor: pointer;
}

/* .......... */

.contentWrapper {
    width: 96%;
    margin: 0 auto;
    padding-bottom: 40px;
}

.cards_mainWrapper .cards_mainPart1 {
    width: 81% !important;
}

.cards_mainWrapper .cards_mainPart2 {
    width: 19% !important;
    position: relative;
}

.cards_mainWrapper .cards_mainPart2 .notes {
    position: absolute !important;
    top: 95% !important;
    margin: 0 20px;
}

.cards_mainWrapper .cards_mainPart2 .notes h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #898989;
    cursor: pointer;
}

.cards_innerWrapper {
    flex-wrap: wrap !important;
}

.cards_mainWrapper .cards_innerWrapper .cards_innerPart1 {
    width: 44% !important;
}

.cards_mainWrapper .cards_innerWrapper .cards_innerPart2 {
    width: 56% !important;
}


/* cards heights starts here */

.cards_mainWrapper .cards_innerWrapper .card {
    height: 70vh;
}


.cards_innerPart1 .tab-content {
    height: calc(70vh - 195px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.cards_innerPart2 .tab-content {
    height: calc(70vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.menu-button:after {
    display: none;
}

@media screen and (max-width: 768px) {
    .cards_mainWrapper .cards_innerWrapper .card {
        height: 100% !important;
    }
}


/* cards heights ends */

.contentWrapper .card .card-title {
    background: #3f80ea;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    padding: 8px 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}


/* customize react bootstrap tabs */

.buttonTabs .nav-tabs {
    border: none !important;
    display: flex;
    justify-content: start;
    padding: 5px 0px;
}

.buttonTabs .nav-tabs .nav-link {
    background: none;
    border: none !important;
    height: 29px;
    width: 100px;
    border-radius: 100px !important;
    margin-right: 5px;
    margin-bottom: 10px;
    line-height: 0px;
}

.buttonTabs2 .nav-tabs .nav-link {
    height: 32px;
    width: 120px;
}

.buttonTabs .nav-tabs .nav-link.active,
.buttonTabs .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    border-color: unset !important;
    background: #3f80ea !important;
}

.buttonTabs .nav-tabs .nav-link {
    color: #494e54;
    background: #e5e5e5 !important;
    border-radius: 10px;
    border-color: unset !important;
}


/* arrow tabs starts here */

.arrowTabs .nav-tabs {
    border: none !important;
    display: flex;
    justify-content: start;
}

.arrowTabs .nav-tabs .nav-link {
    background: none;
    border: none !important;
    height: 50px;
    width: 110px;
    margin-right: 1px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.arrowTabs .nav-tabs .nav-link p {
    text-align: start;
    margin: 0px;
    padding: 0px 0px 0px 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.arrowTabs .nav-tabs .nav-link p b {
    font-weight: 600;
}

.arrowTabs .nav-tabs .nav-link.active,
.arrowTabs .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    border-color: unset !important;
    background: url(../../assets/icons/tabArrows1.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% 100% !important;
}

.arrowTabs .nav-tabs .nav-link.active b,
.arrowTabs .nav-tabs .nav-item.show .nav-link b {
    color: #ffffff;
}

.arrowTabs .nav-tabs .nav-link {
    color: #494e54;
    background: url(../../assets/icons/tabArrows2.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% 100% !important;
    border-color: unset !important;
}


/* arrow tabs ends here */

.disabledTab {
    opacity: 0.6;
}

/* media queries starts here */

@media screen and (max-width: 1200px) {
    .contentWrapper .card .card-title {
        padding: 8px 8px;
    }
}

@media screen and (max-width: 992px) {
    .cards_mainWrapper .cards_innerWrapper .cards_innerPart1 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_innerWrapper .cards_innerPart2 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_mainPart1 {
        width: 71% !important;
    }
    .cards_mainWrapper .cards_mainPart2 {
        width: 29% !important;
    }
    .jobDetails_content .contentHeading .part1 h1 {
        font-size: 30px !important;
    }
    .quoteContainer {
        height: 280px;
    }
}

@media screen and (max-width: 768px) {
    .cards_mainWrapper .cards_mainPart1 {
        width: 100% !important;
    }
    .cards_mainWrapper .cards_mainPart2 {
        width: 100% !important;
        margin: 40px 0;
    }
    .jobDetails_content .contentHeading {
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-start;
    }
    .jobDetails_content .contentHeading .part2 .part2Details {
        text-align: start !important;
    }
    .jobDetails_content .contentHeading .part1 h1 {
        font-size: 25px !important;
    }
    .contentWrapper .card .card-title {
        font-size: 12px !important;
    }
    .cards_mainWrapper .cards_mainPart2 .notes h3 {
        font-size: 14px !important;
    }
}

.invalidValue {
    border-color: #f43737;
}